import { getDefaultCategory, getCategories } from '@/services/CategoryService'
// initial state
const state = () => ({
  categories: [],
  defaultCategory: {},
})

// getters
const getters = {
  getCategories: (state) => {
    return state.categories
  },
  getParentCategories: (state) => {
    const parentCategories = state.categories.filter(
      (category) => category.ParentCategoryID === null
    )
    if (parentCategories.some((category) => category.Ranking !== null)) {
      return parentCategories.sort((a, b) => a.Ranking - b.Ranking)
    }

    // return categories sorted by Description
    return parentCategories.sort((a, b) =>
      a.Description.localeCompare(b.Description)
    )
  },
  getSubCategories: (state) => {
    return state.categories.filter(
      (category) => category.ParentCategoryID !== null
    )
  },
  getDefaultCategory: (state) => state.defaultCategory,
  // eslint-disable-next-line no-unused-vars
  getCategory: (state) => (categoryID) => {
    //parse categoryID as number
    const categories = state.categories
    const category =
      categories.find(
        (category) =>
          category.CategoryID == categoryID || category.Slug === categoryID
      ) ?? null

    return category
  },
}

// actions
const actions = {
  async fetchDefaultCategory({ commit }) {
    const category = await getDefaultCategory()
    commit('setDefaultCategory', category)
  },
  async fetchCategories({ commit }) {
    const categories = await getCategories()
    commit('setCategories', categories.Collection)
  },
}

// mutations
const mutations = {
  setDefaultCategory(state, defaultCategory) {
    state.defaultCategory = defaultCategory
  },

  setCategories(state, categories) {
    state.categories = categories ?? []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
