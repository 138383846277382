import Vue from 'vue'
import store from './state/store'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

import VueClazyLoad from 'vue-clazy-load'
import BreadCrumbs from '@/components/header/BreadCrumbs'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import i18n from './locales/index'

import onlyInt from 'vue-input-only-number'

import '@/globalComponents'

import Toasted from 'vue-toasted'

import './assets/styles/index.css'

Vue.component('BreadCrumbs', BreadCrumbs)

Vue.use(VueClazyLoad)
Vue.use(VueMeta)
Vue.use(onlyInt)

Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 5000,
})

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTM },
  bootstrap: store.getters.getAcceptedCookiesState,
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
