<template>
  <b-button
    :variant="$i18n.locale !== language ? 'link' : 'light'"
    :disabled="$i18n.locale === language"
    class="upper"
    @click="changeOnlyOneParam('locale', language)"
  >
    {{ language }}
  </b-button>
</template>

<script>
export default {
  props: {
    language: {
      required: true,
      type: String,
      default: 'NL',
    },
  },
  methods: {
    async changeOnlyOneParam(paramName, changeTo) {
      let params = this.$route.params
      params[paramName] = changeTo
      this.$i18n.locale = changeTo
      await this.$router.push({ name: this.$route.name, params: params })
      //this.$router.go(0)
    },
  },
}
</script>

<style>
.upper {
  text-transform: uppercase;
}
.language-picker .btn-link {
  color: black;
}
</style>
