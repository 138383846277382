import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

import categories from './modules/categories'

const vuexPersist = new VuexPersist({
  key: 'rentmagic',
  storage: window.localStorage,
})

Vue.use(Vuex)

export default new Vuex.Store({
  actions,
  getters,
  modules: {
    categories,
  },
  mutations,
  namespaced: true,
  plugins: [vuexPersist.plugin],
  state,
})
