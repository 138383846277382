import { axiosCreate } from './Api'
import { getCart } from './CartService'

import store from '../state/store'
import router from '../router'
import i18n from '@/locales/index'

async function authenticate({ token, mobileDevice }) {
  const result = await axiosCreate.get(`shop/authorization/user`, {
    headers: { Authorization: `Bearer ${token}` },
  })
  const accounts = result.data.Accounts.Collection

  const activeAcounts = accounts.filter(
    (account) => account.IsHold === false && account.IsActive === true
  )

  if (result.data.Customer) {
    store.commit('setBearerToken', {
      token: `Bearer ${result.data.Token.Access_token}`,
    })

    await store.commit('setUser', { user: result.data })
    await getCart()
  }

  if (result.data.Error?.Message) {
    if (result.data.Error?.Message == 'IncorrectDetails') {
      return i18n.tc('invalid-email-or-password')
    } else if (result.data.Error.Message == 'UserNotApproved') {
      return i18n.tc('email-not-confirmed')
    } else if (result.data.Error.Message == 'UserLockedOut') {
      return i18n.tc('account-blocked')
    } else {
      return i18n.tc('account-not-activated')
    }
  }

  if (activeAcounts.length === 0 || result.data === '' || result.data.IsHold) {
    return i18n.tc('account-no-client')
  }

  await getCart()

  if (activeAcounts.length > 1) {
    router.push({
      name: 'profileSelector',
    })
    return
  }

  if (mobileDevice) {
    router.push({ name: 'categories' })
    return
  }

  router.push({
    path: `/${i18n.locale}`,
    query: { loggedIn: true },
  })
}

async function login({ username, password, mobileDevice }) {
  const result = await axiosCreate.get('shop/authorization/login', {
    auth: {
      username: username,
      password: password,
    },
  })

  const accounts = result.data.Accounts.Collection

  const activeAcounts = accounts.filter(
    (account) => account.IsHold === false && account.IsActive === true
  )

  if (result.data.Error?.Message) {
    if (result.data.Error?.Message === 'IncorrectDetails') {
      return i18n.tc('invalid-email-or-password')
    } else if (result.data.Error.Message === 'UserNotApproved') {
      return i18n.tc('email-not-confirmed')
    } else if (result.data.Error.Message === 'UserLockedOut') {
      return i18n.tc('account-blocked')
    } else {
      return i18n.tc('account-not-activated')
    }
  }

  if (result.status >= 500) {
    return i18n.tc('Something went wrong, please try again later')
  }

  if (result.status >= 400) {
    return `${i18n.tc('The email or password you have entered is incorrect')}.`
  }

  if (activeAcounts.length === 0 || result.data === '' || result.data.IsHold) {
    return i18n.tc('account-no-client')
  }

  if (result.data.Customer) {
    const accounts = result.data.Accounts.Collection
    //let activeAcountsCount = 0
    let activeAcounts = []

    for (let account of accounts) {
      if (account.IsCustomerActive === false) continue
      activeAcounts.push(account)
    }

    store.commit('setBearerToken', {
      token: `Bearer ${result.data.Token.Access_token}`,
    })

    await store.commit('setUser', { user: result.data })
    //await copyCart()
  }

  getCart()

  if (activeAcounts.length > 1) {
    router.push({
      name: 'profileSelector',
    })
    return
  }

  if (mobileDevice) {
    router.push({ name: 'categories' })
    return
  }

  router.push({
    path: `/${i18n.locale}`,
    query: { loggedIn: true },
  })
}

async function anonymousCheckout({
  email,
  street,
  houseNumber,
  postcode,
  city,
  tel,
  companyName,
  website,
  firstname,
  lastname,
  CONTACT_email,
  CONTACT_street,
  CONTACT_houseNumber,
  CONTACT_postcode,
  CONTACT_city,
  CONTACT_tel,
  CONTACT_MobilePhone,
  InvoiceCompanyName,
  InvoiceStreet,
  InvoiceHouseNumber,
  InvoiceHouseNumberAddition,
  InvoiceZipCode,
  InvoiceCity,

  InvoiceTel,
  InvoiceEmail,
  InvoiceFirstName,
  InvoiceLastName,
  VATNo,
  Locale,
} = {}) {
  const result = await axiosCreate.post(
    'shop/cart/checkout',
    {
      Email: email,
      FirstName: firstname,
      LastName: lastname,
      Tel: tel,
      CompanyName: companyName,
      Street: street,
      ReceiverName: `${firstname} ${lastname}`,
      HouseNumber: houseNumber,
      ZipCode: postcode,
      City: city,
      UserEmail: CONTACT_email,
      CONTACT_Email: CONTACT_email,
      CONTACT_Tel: CONTACT_tel,
      CONTACT_Street: CONTACT_street,
      CONTACT_HouseNumber: CONTACT_houseNumber,
      CONTACT_ZipCode: CONTACT_postcode,
      CONTACT_City: CONTACT_city,
      CONTACT_MobilePhone,
      LanguageID: Locale,
      Website: website,
      ConfirmURL: `${process.env.VUE_APP_FRONTEND_URL}/${i18n.locale}/account-aangemaakt/`,
      Salutation: '',
      InvoiceCompanyName,
      InvoiceStreet,
      InvoiceHouseNumber,
      InvoiceHouseNumberAddition,
      InvoiceZipCode,
      InvoiceCountryID: 'be',
      InvoiceCity,
      InvoiceTel,
      InvoiceEmail,
      InvoiceFirstName,
      InvoiceLastName,
      VATNo,
      note: '',
    },
    {
      headers: { authorization: store.getters.token },
    }
  )
  return result
}

async function confirmUser({ code }) {
  const result = await axiosCreate.post(
    'shop/authorization/confirmuser',
    null,
    {
      params: {
        code,
      },
    }
  )
  return result
}

async function getSubs({customerId}= {}) {
  const result = await axiosCreate.get(
    `shop/authorization/${store.getters.contactID}/subaccounts`,
    {
      headers: { authorization: store.getters.token },
      params: {CustomerID: customerId}
    }
  )
  store.commit('setSubUsers', { subUsers: result.data.Collection })
  return result.data.Collection
}

async function requestPasswordReset({ email }) {
  const result = await axiosCreate.post('shop/authorization/lostpassword', {
    Email: email,
    LanguageID: 'NL',
    URL: `${process.env.VUE_APP_FRONTEND_URL}/${i18n.locale}/wachtwoord-herstellen?code=<code>`,
  })
  return result
}

async function setNewPassword({ code, password, confirmPassword }) {
  const result = axiosCreate.post('shop/authorization/setpassword', {
    Code: code,
    Password: password,
    ConfirmPassword: confirmPassword,
  })
  return result
}

async function modifySub({
  email,
  password,
  confirmPassword,
  street,
  city,
  houseNumber,
  postcode,
  tel,
  firstname,
  lastname,
  contactID,
  userName,
  CONTACT_MobilePhone,
  MobilePhone,
  CUST_ReceiveNewsletter,
}) {
  const result = await axiosCreate.patch(
    `shop/authorization/${
      store.getters.contactID
    }/subaccounts/${encodeURIComponent(userName)}/${contactID}`,
    {
      ContactID: contactID,
      UserEmail: email,
      Password: password,
      ConfirmPassword: confirmPassword,
      Email: email,
      FirstName: firstname,
      LastName: lastname,
      CUST_Street: street,
      CUST_HouseNumber: houseNumber,
      CUST_ZipCode: postcode,
      Tel: tel,
      CUST_City: city,
      CONTACT_MobilePhone,
      MobilePhone,
      CUST_ReceiveNewsletter,
    },
    {
      headers: { authorization: store.getters.token },
    }
  )
  return result.data
}

async function deleteSub({ subUserName, subUserContactID }) {
  const result = await axiosCreate.delete(
    `shop/authorization/${
      store.getters.contactID
    }/subaccounts/${encodeURIComponent(subUserName)}/${subUserContactID}`,
    {
      headers: { authorization: store.getters.token },
    }
  )
  return result
}

async function updateCustomer({
  Email,
  CustomerStreet,
  CustomerEmail,
  ZipCode,
  CustomerDescription,
  HouseNumber,
  HouseNumberAddition,
  ECCUST_EnterpriseNumber,
  City,
  CompanyName,
  Website,
  CUST_SocialMedia,
}) {
  const result = await axiosCreate.patch(
    `shop/customers/${store.getters.customerID}`,
    {
      CustomerID: store.getters.customerID,
      Email,
      Street: CustomerStreet,
      ZipCode,
      HouseNumber,
      HouseNumberAddition,
      ECCUST_EnterpriseNumber,
      City,
      Website,
      CompanyName,
      CUST_Notes: CustomerDescription,
      CUST_SocialMedia,
      CustomerEmail,
    },
    {
      headers: { authorization: store.getters.token },
    }
  )

  store.commit('updateUser', {
    user: {
      Email,
      CustomerStreet,
      ZipCode,
      HouseNumber,
      HouseNumberAddition,
      ECCUST_EnterpriseNumber,
      City,
      Website,
      CompanyName,
      CUST_Notes: CustomerDescription,
      CUST_SocialMedia: CUST_SocialMedia,
    },
  })

  store.commit('updateAccount', {
    user: {
      Email,
      CustomerStreet,
      ZipCode,
      HouseNumber,
      HouseNumberAddition,
      ECCUST_EnterpriseNumber,
      City,
      Website,
      CompanyName,
      CUST_Notes: CustomerDescription,
      CUST_SocialMedia: CUST_SocialMedia,
    },
  })

  return result
}

async function transferOwnership({ subUserName, subUserContactID }) {
  const result = await axiosCreate.post(
    `shop/authorization/${
      store.getters.contactID
    }/subAccounts/${encodeURIComponent(
      subUserName
    )}/${subUserContactID}/promote`,
    {},
    {
      headers: { authorization: store.getters.token },
    }
  )
  return result
}

async function getAnonymousToken() {
  const result = await axiosCreate.get(`shop/authorization/anonymous`)
  return result
}

module.exports = {
  getAnonymousToken,
  login,
  getSubs,
  modifySub,
  updateCustomer,
  deleteSub,
  requestPasswordReset,
  setNewPassword,
  confirmUser,
  transferOwnership,
  anonymousCheckout,
  authenticate,
}
